<template>
    <div>
        <!-- main page -->
        <div class="vx-row mb-12">
            <div class="vx-col sm:w-1/1 w-full mb-base">
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <vs-dropdown vs-trigger-click class="cursor-pointer">
                            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                                <span class="mr-2">{{this.dataEnd==0 ? 0: (this.limitShow * (this.isActive - 1) + 1)}} - {{this.limitShow * (this.isActive - 1) + dataEnd}} of {{this.recordsTotal}}</span>
                                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                            </div>
                            <vs-dropdown-menu>
                                <vs-dropdown-item v-for="item in limits" v-bind:key="item" @click="changeLimit(item)">
                                    <span>{{item}}</span>
                                </vs-dropdown-item>
                            </vs-dropdown-menu>
                        </vs-dropdown>
                    </div>
                    <div class="mx-auto">
                        <vs-button class="ml-4 mt-2" color="primary" icon-pack="feather" icon="icon-prin" @click="handleExport('excel')">Download Excel</vs-button>
                    </div>
                    <div class="vx-col sm:w-1/3 w-full">
                        <div class="flex flex-wrap items-center justify-between">
                            <vs-input class="mb-4 md:mb-0 mr-4 w-full" v-model="search" placeholder="Search" />
                        </div>
                    </div>
                </div>

                <vs-table :data="dataSalesOrderLine" stripe border>
                    <template slot="thead">
                        <vs-th>Sales Office</vs-th>
                        <vs-th>Sales Office Desc</vs-th>
                        <vs-th>Dist Channel</vs-th>
                        <vs-th>Div Code</vs-th>
                        <vs-th>Div Desc</vs-th>
                        <vs-th>Sold To Party</vs-th>
                        <vs-th>Sold To Party Name</vs-th>
                        <vs-th>Material Code</vs-th>
                        <vs-th>Material Desc</vs-th>
                        <vs-th>Product Category</vs-th>
                        <vs-th>PO Number</vs-th>
                        <vs-th>SO Number</vs-th>
                        <vs-th>SO Date</vs-th>
                        <vs-th>DO Number</vs-th>
                        <vs-th>GI Number</vs-th>
                        <vs-th>Billing Number</vs-th>
                        <vs-th>Billing Date</vs-th>
                        <vs-th>Billing Posting Date</vs-th>
                        <vs-th>Actual GI Date</vs-th>
                        <vs-th>Billing Type</vs-th>
                        <vs-th>Order Type</vs-th>
                        <vs-th>Order Type Desc</vs-th>
                        <vs-th>Sales Code</vs-th>
                        <vs-th>Sales Desc</vs-th>
                        <vs-th>Bill Net Value</vs-th>
                        <vs-th>Doc Status</vs-th>
                        <vs-th>Cancel Reason</vs-th>
                        <vs-th>COGS</vs-th>
                        <vs-th>Bill Gross Value</vs-th>
                        <vs-th>Gross Margin</vs-th>
                        <vs-th>Gross Margin Percentage</vs-th>
                        <vs-th>Bill Qty</vs-th>
                        <vs-th>Bill Unit</vs-th>
                        <vs-th>Bill Qty Lvl 3</vs-th>
                        <vs-th>Bill Unit Lvl 3</vs-th>
                        <vs-th>Bill Weight</vs-th>
                        <vs-th>Bill Weight Unit</vs-th>
                        <vs-th>Bill Item</vs-th>
                        <vs-th>Currency</vs-th>
                        <vs-th>Discount Principal</vs-th>
                        <vs-th>Discount Dist</vs-th>
                        <vs-th>TOPCode</vs-th>
                        <vs-th>TOPName</vs-th>
                        <vs-th>Cust Grp</vs-th>
                        <vs-th>Cust Grp Desc</vs-th>
                        <vs-th>Cust Grp1</vs-th>
                        <vs-th>Cust Grp 1 Desc</vs-th>
                        <vs-th>Cust Grp 2</vs-th>
                        <vs-th>Cust Grp 2 Desc</vs-th>
                        <vs-th>Cust Grp 3</vs-th>
                        <vs-th>Cust Grp 3 Desc</vs-th>
                        <vs-th>Cust Grp 4</vs-th>
                        <vs-th>Cust Grp 4 Desc</vs-th>
                        <vs-th>Posting Type</vs-th>
                    </template>

                    <template slot-scope="{data}">
                        <vs-tr :key="indexSO" v-for="(dt, indexSO) in data">
                            <vs-td>{{dt.OperatingUnit ? dt.OperatingUnit.Code : '-'}}</vs-td>
                            <vs-td>{{dt.OperatingUnit ? dt.OperatingUnit.Name : '-'}}</vs-td>
                            <vs-td>{{dt.Customer ? dt.Customer.CustomerCategory.Name : '-'}}</vs-td>
                            <vs-td>{{dt.Supplier ? dt.Supplier.Code : ""}}</vs-td>
                            <vs-td>{{dt.Supplier ? dt.Supplier.Name : ""}}</vs-td>
                            <vs-td>{{dt.Customer ? dt.Customer.Code : ""}}</vs-td>
                            <vs-td>{{dt.Customer ? dt.Customer.Name : ""}}</vs-td>
                            <vs-td>{{dt.ItemCode}}</vs-td>
                            <vs-td>{{dt.ItemName}}</vs-td>
                            <vs-td>{{dt.Jenis == "so" ? "SELL" : dt.Jenis == "sr" ? "Return"  : dt.Jenis == "sr-revers" ? "Return Reverse" : "SELL Reverse"}}</vs-td>
                            <vs-td>{{dt.ReferenceCode}}</vs-td>
                            <vs-td>{{dt.Code}}</vs-td>
                            <vs-td>{{dateFormat(dt.Date)}}</vs-td>
                            <vs-td>{{dt.Jenis == "so" ?  dt.DeliveryOrder ? dt.DeliveryOrder.Code : "" : dt.DeliveryReturn.Code}}</vs-td>
                            <vs-td>{{dt.Jenis == "so" && dt.ProofOfDelivery ? dt.ProofOfDelivery.GiNumber : dt.GirCode}}</vs-td>
                            <vs-td>{{dt.Jenis == "so" && dt.Invoice ? dt.Invoice.Code : dt.CreditNote.Code}}</vs-td>
                            <vs-td>{{dt.Jenis == "so" && dt.Invoice ? dateFormat(dt.Invoice.Date) : dt.Jenis == "sr" ? dateFormat(dt.CreditNote.Date) : dt.Jenis == "sr-revers" ? dateFormat(dt.CreditNote.Date) : dt.Jenis == "so-revers" && dt.Invoice ?  dateFormat(dt.Invoice.DefaultAttribute.CreatedAt) : ""}}</vs-td>
                            <vs-td>{{dt.Jenis == "so" && dt.Invoice ? dateFormat(dt.Invoice.PostingDate) : dt.Jenis == "sr" ? dateFormat(dt.CreditNote.PostingDate) : dt.Jenis == "sr-revers" ?  dateFormat(dt.ReversalDate) : dt.Jenis == "so-revers" && dt.Invoice ? dateFormat(dt.Invoice.DateReversal) : ""}}</vs-td>
                            <vs-td>{{dt.Jenis == "so" ? dt.ProofOfDelivery ? dateFormat(dt.ProofOfDelivery.Date) : "" : dateFormat(dt.Date)}}</vs-td>
                            <vs-td>{{dt.Jenis == "so" ? "Customer Invoice" : dt.Jenis == "sr" ? "Customer Return"  : dt.Jenis == "sr-revers" ? " Customer Return reverse" : " Customer Invoice reverse"}}</vs-td>
                            <vs-td>{{dt.Jenis == "so" ? "ZDSO" : dt.Jenis == "sr" ? "ZDCR"  : dt.Jenis == "sr-revers" ? "ZDCRR" : "ZDSOR"}}</vs-td>
                            <vs-td>Special Order</vs-td>
                            <vs-td>{{dt.SalesmanPersonal ? dt.SalesmanPersonal.CodeExternal : ""}}</vs-td>
                            <vs-td>{{dt.SalesmanPersonal ? dt.SalesmanPersonal.Name : ""}}</vs-td>
                            <vs-td align="right">{{dt.Jenis == "so" || dt.Jenis == "sr-revers" ? formatCurrency(dt.Subtotal+dt.Discount) : "-" + formatCurrency(dt.Subtotal)}}</vs-td>
                            <vs-td>{{dt.Jenis == "so" || dt.Jenis == "sr" ? "APPLIED" : "REVERSED"}}</vs-td>
                            <vs-td></vs-td>
                            <vs-td align="right">{{dt.Jenis == "so" || dt.Jenis == "sr-revers" ? "" : "-"}}{{formatCurrency(dt.TotalCogs)}}</vs-td>
                            <vs-td align="right">{{dt.Jenis == "so" || dt.Jenis == "sr-revers" ? formatCurrency(dt.Subtotal) : "-" + formatCurrency(dt.Subtotal+dt.Discount)}}</vs-td>
                            <vs-td align="right">{{dt.Jenis == "so" ? "" : "-"}}{{formatCurrency(dt.Subtotal+dt.Discount - dt.TotalCogs)}}</vs-td>
                            <vs-td align="right">{{dt.TotalCogs == 0 ? "0" : (dt.Subtotal+dt.Discount - dt.TotalCogs) / dt.TotalCogs * 100}}%</vs-td>
                            <vs-td align="right">{{dt.Quantity}}</vs-td>
                            <vs-td align="right">{{dt.ItemUnit}}</vs-td>
                            <vs-td align="right">{{dt.QuantityLvl3}}</vs-td>
                            <vs-td align="right">{{dt.ItemUnitLvl3}}</vs-td>
                            <vs-td></vs-td>
                            <vs-td></vs-td>
                            <vs-td></vs-td>
                            <vs-td>IDR</vs-td>
                            <vs-td align="right">{{formatCurrency(0)}}</vs-td>
                            <vs-td align="right">{{formatCurrency(0)}}</vs-td>
                            <vs-td>{{dt.PaymentTerm ? dt.PaymentTerm.Name : ""}}</vs-td>
                            <vs-td>{{dt.PaymentTerm ? dt.PaymentTerm.DueInvoicePeriod : ""}} Days</vs-td>
                            <vs-td>{{dt.CustomerGroup ? dt.CustomerGroup.Code : '' }}</vs-td>
                            <vs-td>{{dt.CustomerGroup ? dt.CustomerGroup.Name : '' }}</vs-td>
                            <vs-td>{{dt.CustomerGroup1 ? dt.CustomerGroup1.Code : '' }}</vs-td>
                            <vs-td>{{dt.CustomerGroup1 ? dt.CustomerGroup1.Name : '' }}</vs-td>
                            <vs-td>{{dt.CustomerGroup2 ? dt.CustomerGroup2.Code : '' }}</vs-td>
                            <vs-td>{{dt.CustomerGroup2 ? dt.CustomerGroup2.Name : '' }}</vs-td>
                            <vs-td>{{dt.CustomerGroup3 ? dt.CustomerGroup3.Code : '' }}</vs-td>
                            <vs-td>{{dt.CustomerGroup3 ? dt.CustomerGroup3.Name : '' }}</vs-td>
                            <vs-td>{{dt.CustomerGroup4 ? dt.CustomerGroup4.Code : '' }}</vs-td>
                            <vs-td>{{dt.CustomerGroup4 ? dt.CustomerGroup4.Name : '' }}</vs-td>
                            <vs-td></vs-td>
                        </vs-tr>
                    </template>
                </vs-table>

                <vs-pagination style="padding-top:5px" :total="maxPage" v-model="currentPage" :prev-icon="$vs.rtl ? 'arrow_forward' : 'arrow_back'" :next-icon="$vs.rtl ? 'arrow_back' : 'arrow_forward'"/>
            </div>
        </div>
    </div>
</template>


<script>
import moment from 'moment'

export default {
    components: {
        //
    },
    props:
        ["progress", "territory_ids", "progressWarehouse", "progressRestriction", "isCancel","operatingUnit", "customerCategory", "salesPersonal", "territories", "filterDate", "filterPostingDate", "filterBillingDate", "draw", "supplier", "soDate"],
        mounted() {
            //
        },
        data() {
            return {
                dataSalesOrderLine: [],
                limits: [10, 25, 50, 100, "All"],
                limitShow: 10,
                drawData: 0,
                isActive: 1,
                search: "",
                detailShow: "core vx-col md:w-1/2 w-full mb-base ",
                detailHide: "core vx-col md:w-1/1 w-full mb-base ",
                detail: false,
                recordsTotal: 0,
                maxPage: 2,
                dataEnd: 0,
            };
        },
    watch: {
        watchedProperties: function() {
            this.getData();
        }
    },
    computed: {
        watchedProperties() {
            return `${this.draw}||${this.search}`;
            // return `${this.territories}||${this.customerCategory}||${this.search}||${this.salesPersonal.id}||${this.filterDate.startDate}||${this.filterDate.endDate}||${this.filterPostingDate.startDate}||${this.filterPostingDate.endDate}||${this.filterBillingDate.startDate}||${this.filterBillingDate.endDate}`;
        },
        currentPage: {
            get() {
                return 1;
            },
            set(page) {
                this.page(page);
            }
        }
    },
    methods: {
        s2ab(s) {
            var buf = new ArrayBuffer(s.length);
            var view = new Uint8Array(buf);
            for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
            return buf;
        },

        dateFormat(date) {
            if (date) {
                return moment(String(date)).format('DD/MM/YYYY')
            }
        },

        formatCurrency(nominal) {
            if(nominal) {
                nominal = Math.abs(nominal)
                nominal = (nominal/1).toFixed(2).replace('.', ',');
                return nominal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            } else {
                return 0;
            }
        },

        scrollToTop() {
            window.scrollTo(0,0);
        },

        clickSOCode(salesOrder) {
            this.closeDetail();
            setTimeout(function() {}, 3000);
            this.salesOrderCode = salesOrder.Code;
            this.getSOLine();
            this.detail = true;
            this.scrollToTop();
        },

        closeDetail() {
            this.detail = false;
            this.$emit("selectSalesOrder", null);
        },

        page(page) {
            this.getData(page);
            return page;
        },

        changeLimit(limit) {
            this.limitShow = limit;
            this.getData(this.isActive);
        },

        getData(page = 1) {
            console.log("this.drawdata", this.draw)
            console.log("this.soDate", this.soDate)
            if (this.draw != 0){
                if (this.territories == null || this.territories == "" || this.territories == [] || this.territories == undefined) {
                    return true;
                }
                this.isActive = page;
                this.$vs.loading();

                var parameter = {
                    territories: this.territories,
                    territory_ids: this.territory_ids,
                    customer_category_ids: null,//this.customerCategory,
                    supplier_ids: null,//this.supplier,
                    sales_personal_ids: null,//this.salesPersonal,
                    search: null,//this.search,
                    limit: this.limitShow,
                    offset: this.limitShow * (page - 1),
                    start_date: null,//this.filterDate.startDate != null ? moment(this.filterDate.startDate).format("YYYY-MM-DD") : null,
                    end_date: null,//this.filterDate.endDate != null ? moment(this.filterDate.endDate).format("YYYY-MM-DD") : null,
                    start_posting_date: null,//this.filterPostingDate.startDate != null ? moment(this.filterPostingDate.startDate).format("YYYY-MM-DD") : null,
                    end_posting_date: null,//this.filterPostingDate.endDate != null ? moment(this.filterPostingDate.endDate).format("YYYY-MM-DD") : null,
                    start_billing_date: this.filterBillingDate.startDate != null ? moment(this.filterBillingDate.startDate).format("YYYY-MM-DD") : null,
                    end_billing_date: this.filterBillingDate.endDate != null ? moment(this.filterBillingDate.endDate).format("YYYY-MM-DD") : null,
                    //so_date: this.soDate != null ? moment(this.soDate).format("YYYY-MM-DD") : null,
                };

                console.log("parameter", parameter)
                this.$http.get("/api/v1/report/zdr-bill", {
                    params: parameter
                }).then(resp => {
                    if (resp.code == 200) {
                        this.drawData = this.drawData + 1;
                        this.dataSalesOrderLine = resp.data.SalesOrderLines;
                        this.recordsTotal = resp.data.RecordsTotal;
                        this.maxPage = Math.ceil(this.recordsTotal / this.limitShow);
                        this.dataEnd = resp.data.ResultsTotal;
                        this.checkedAll = false;
                        this.$vs.loading.close();
                    } else {
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: resp.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle"
                        });
                    }
                });
            }
        },
        handleExport(){
            console.log("this.dataSalesOrderLine", this.dataSalesOrderLine);
            if(this.dataSalesOrderLine.length <= 0) {
                this.$vs.dialog({
                    type: "confirm",
                    color: "danger",
                    title: `Confirm`,
                    text: "Are you sure want to download before preview data ?",
                    accept: this.doExport,
                });
            } else {
                this.doExport('excel');
            }
        },
        randomString(length) {
            var result           = '';
            var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < length; i++ ) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            return result;
        },
        generateFilename() {
            this.randomString(6)
            return "ZDRBillReport_"+this.randomString(6)+"_"+moment(new Date()).format("YYYYMMDDHHmmss")
        },
        doExport() {
            this.$vs.loading();
            var fileTitle = this.generateFilename();
            
            console.log("this.territories", this.territories)
            let datas = []
            this.territory_ids = ""

            this.territories.forEach(element => {
                if (element.ID != 0) {
                    datas.push(element.ID)
                }
                this.territory_ids = datas.join(",")
            });
            console.log("this.territory_ids", this.territory_ids)
            console.log("this.soDate", this.soDate)

            this.$http
                .get("/api/v1/report/zdr-bill/export", {
                params: {
                   territories: this.territories,
                    territory_ids: this.territory_ids,
                    customer_category_ids: null,//this.customerCategory,
                    supplier_ids: null,//this.supplier,
                    sales_personal_ids: null,//this.salesPersonal,
                    limit: 100000000,
                    start_date: null,//this.filterDate.startDate != null ? moment(this.filterDate.startDate).format("YYYY-MM-DD") : null,
                    end_date: null,//this.filterDate.endDate != null ? moment(this.filterDate.endDate).format("YYYY-MM-DD") : null,
                    start_posting_date: null,//this.filterPostingDate.startDate != null ? moment(this.filterPostingDate.startDate).format("YYYY-MM-DD") : null,
                    end_posting_date: null,//this.filterPostingDate.endDate != null ? moment(this.filterPostingDate.endDate).format("YYYY-MM-DD") : null,
                    start_billing_date: this.filterBillingDate.startDate != null ? moment(this.filterBillingDate.startDate).format("YYYY-MM-DD") : null,
                    end_billing_date: this.filterBillingDate.endDate != null ? moment(this.filterBillingDate.endDate).format("YYYY-MM-DD") : null,
                    //so_date: this.soDate != null ? moment(this.soDate).format("YYYY-MM-DD") : null,
                    title: fileTitle,
                    file: 'excel',
                },
                })
                .then((resp) => {
                this.$vs.loading.close();
                if (resp.status == "error") {
                    this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: resp.message,
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                    });
                } else {
                    this.$vs.notify({
                    color: "success",
                    title: "Processing",
                    text: resp.message,
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                    });
                }
                });
        },
    }
};
</script>

<style>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
</style>
